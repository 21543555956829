<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getDataReport"
  :isLoading="isLoad"
  :actions="actions"
  isHiddenTableSearch)
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { headerStatisticTableExcels } from './constants'

export default {
  data () {
    return {
      headers: headerStatisticTableExcels(),
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.saveReport',
          action: (item) => this.getDocumentReportExcel(item),
          color: 'green',
          name: 'mdi-monitor-arrow-down-variant',
          isView: true
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.statistics.excels,
      isLoad: state => state.statistics.isLoad

    })
  },
  methods: {
    ...mapActions(['getListStatisticsReportExcel', 'getDocumentReportExcel']),
    async getDataReport (filter) {
      await this.getListStatisticsReportExcel(filter)
    }
  }
}
</script>
